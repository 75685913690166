<br><br>
<h1>Über uns</h1>
<br><br>
<p>Willkommen bei Chequeo.de - deinem kostenlosen digitalen Vokabeltest.
    Teste deine Schüler einfach online und spare
    dir viel Zeit beim Korrigieren. Unser Tool ermöglicht eine schnelle und einfache Auswertung ohne Korrekturaufwand.
    So ist ein durchschnittlicher Vokabeltest bereits nach einer Minute berichtigt und die Schüler erhalten ein direktes
    Feedback. Zusätzlich wird durch einen speziellen Testmodus Schummeln unmöglich. Es ist dabei keine Registrierung der
    Klasse notwendig - du kannst direkt loslegen. Nutze die Bibliothek um auf das Wissen der Community zuzugreifen oder
    teile ganze Tests mit deinen Kollegen.
    Chequeo.de ist völlig gebührenfrei und ermöglicht dir eine reibungslose und effektive Durchführung von Vokabeltests.
    Starte noch heute und erlebe die Vorteile eines digitalisierten Vokabeltests.</p>