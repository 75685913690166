import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  loadBody() {
    this.router.navigate(['/']);
  }
  loadImprint() {
    this.router.navigate(["/imprint"]);
  }

  loadPrivacy() {
    this.router.navigate(["/privacy"]);
  }
  loadContact() {
    this.router.navigate(["/contact"]);
  }
  loadTutorial() {
    this.router.navigate(["/tutorial"]);
  }
  loadAboutUs() {
    this.router.navigate(["/about-us"]);
  }
}
