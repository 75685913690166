<!-- <div class="stuff">
    <h1>Impressum</h1>
</div> -->

<!-- <div class="stuff"> -->
<div [style.height.vh]="70">
    <h1>Impressum</h1>

    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>Dennis Padrok<br />
        Rosenweg 17<br />
        30457 Hannover</p>

    <p>Telefon: +49 (0) 1704961929<br />
        Telefax: +49 (0) 51180904727<br />
        E-Mail: chequeo.app&#64;gmail.com</p>

</div>
