<div class="header">
    <div class="logoheader">
        <img src="/assets/img/chequeo-logo.webp" id="logoCorner" 
            alt="Chequeo.de - der digitale Vokabeltest" (click)="this.loadBody()">
        <h1 class="p1" (click)="this.loadBody()">Chequeo</h1>
        <p class="p1" (click)="this.loadBody()">- der digitale Vokabeltest</p>
    </div>
    <button mat-icon-button class="matMenu" [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="this.loadAboutUs()">
            <mat-icon>info</mat-icon>
            <span>Über uns</span>
        </button>
        <button mat-menu-item (click)="this.loadTutorial()">
            <mat-icon>pageview</mat-icon>
            <span>Tutorial</span>
        </button>
        <button mat-menu-item (click)="this.loadPrivacy()">
            <mat-icon>lock</mat-icon>
            <span>Datenschutz</span>
        </button>
        <button mat-menu-item (click)="this.loadContact()">
            <mat-icon>perm_phone_msg</mat-icon>
            <span>Kontakt</span>
        </button>
        <button mat-menu-item (click)="this.loadImprint()">
            <mat-icon> place</mat-icon>
            <span>Impressum</span>
        </button>
    </mat-menu>
</div>
