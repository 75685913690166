import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent implements OnInit {

  prod = environment.production;

  constructor() { }

  ngOnInit(): void {
  }

  linkTeachers() {
    //ToDo
  }

  linkStudents() {
    //ToDo
  }

}
